/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


let awsmobile = {}
// let currentStage = 'dev'
const currentStage = 'beta'
// console.log(currentStage)
// switch to AWS_IAM from "aws_appsync_authenticationType": "API_KEY",
if ( currentStage === 'dev' || currentStage === 'beta')   {
    awsmobile = {
        "aws_project_region": "us-east-1",
        "aws_appsync_graphqlEndpoint": "https://efkypdtrnne37djo7ryeqlbwqm.appsync-api.us-east-1.amazonaws.com/graphql",
        "aws_appsync_region": "us-east-1",
        "aws_appsync_authenticationType": "API_KEY",
        "aws_cognito_identity_pool_id": "us-east-1:5c9e1b7e-27cb-48f7-830c-5d63a00426ca",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_Y4Uruq6Fr",
        "aws_user_pools_web_client_id": "755esgbgutiu00cb261ebe8297",
        "oauth": {},
        "aws_cognito_username_attributes": [],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ],
        "aws_user_files_s3_bucket": "ui4bi-bucket84824-dev",
        "aws_user_files_s3_bucket_region": "us-east-1"
    };
}

// Prod settings here...
if (currentStage === 'prod')   {

    const awsmobile = {
        "aws_project_region": "us-east-1",
        "aws_appsync_graphqlEndpoint": "https://cbfwnh64snbanp4i5ubi2nfbmy.appsync-api.us-east-1.amazonaws.com/graphql",
        "aws_appsync_region": "us-east-1",
        "aws_appsync_authenticationType": "API_KEY",
        "aws_cognito_identity_pool_id": "us-east-1:6693f94a-5f7f-451e-ad8b-96e6c95c471f",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_ob8SlxR4v",
        "aws_user_pools_web_client_id": "4c7kgaava7uqu7mmkpreqc3bdd",
        "oauth": {},
        "aws_cognito_username_attributes": [
            "EMAIL",
            "PHONE_NUMBER"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ],
        "aws_user_files_s3_bucket": "ui4bi-prod-bucket164517-dev",
        "aws_user_files_s3_bucket_region": "us-east-1"
    };
    

        
}



export default awsmobile;
